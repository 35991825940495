<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto;">
    <div id="mockPrinciple">
      <div class="pageTitle-mp">全国计算机技术与软件专业技术资格(水平)考试应考人员考场守则</div>
      <div class="content-mp">
        <div class="inputContainer-mp" :style="{height: contentHeight + 'px'}">
          <span class="inputTitle-mp">请认真阅读考场守则至文末，点击“阅读并同意遵守”。</span>
          <a-divider :margin="0"></a-divider>
          <a-scrollbar :style="{width: '1200px',height: contentHeight - 124 + 'px',overflowX: 'hidden',overflowY: 'visible'}">
            <div class="principleContainer">
              <a-space :size="32" direction="vertical">
                <div style="width: 100%;display: flex;" v-for="(item,index) in principle" :key="index">
                  <div class="principleIndex">{{item.index}}</div>
                  <div class="principleContent">{{item.content}}</div>
                </div>
              </a-space>
            </div>
          </a-scrollbar>
          <a-divider :margin="0"></a-divider>
          <div class="buttonBlock">
            <a-button style="width: 178px;height: 36px" @click="goBack">返回</a-button>
            <a-button
                id="confirmBtn"
                :style="{width: '178px',height: '36px',
                background: btnBackground,
                marginLeft: '60px',
                color: '#FFFFFF'}"
                :disabled="disabled"
                @click="moveToNext"
            >阅读并同意遵守<span v-if="countDown>0">{<span>{{countDown}}</span>s}</span></a-button>
          </div>
        </div>
      </div>
    </div>
  </a-scrollbar>
</template>

<style>
#mockPrinciple {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  //padding-bottom: 40px;
  margin: 0 auto;
  display: flex;
  //background: linear-gradient(#1F63FB,#428DFF);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background: url("../assets/back.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.pageTitle-mp{
  margin-top: 32px;
  font-size: 32px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 45px;
  text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
}
.content-mp{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.inputContainer-mp{
  margin-top: 32px;
  width: 1200px;
  //height: 875px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inputTitle-mp{
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #F53F3F;
  line-height: 24px;
}
.principleContainer{
  display: flex;
  flex-direction: column;
  padding: 32px;
  overflow: hidden;
}
.buttonBlock{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.principleIndex{
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #1D2129;
  line-height: 24px;
}
.principleContent{
  margin-left: 24px;
  width: 1048px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  line-height: 24px;
}
.dialogContent{
  display: flex;
  flex-direction: column;
}
.dialogParam1{
  font-size: 14px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}
.dialogParam2{
  font-size: 14px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
  margin-top: 12px;
}
</style>
<script setup>
import { Modal } from '@arco-design/web-vue';
import { h } from 'vue'
import {useRouter} from "vue-router";
import {ref , onMounted} from "vue";

let contentHeight = ref(0)

window.onresize = function (){
  contentHeight.value = window.innerHeight - 149
}

onMounted(() => {
  contentHeight.value = window.innerHeight - 149
  console.log('height',contentHeight.value)
})

const router = useRouter()
let countDown = ref(9)
let disabled = ref(true)
let btnBackground = ref("#94BFFF")

const vnode = h(
    'div', // type
    { class: 'dialogContent' }, // props
    [
      h(
          'div',
          {class: 'dialogParam1'},
          '严禁携带纸质资料、手机及其他电子设备 （如智能手表、智能手环、智能眼镜......）等与考试无关的物品进入考场座位，考试期间，一经发现将严格按照违规行为处理办法进行处理。'
      ),
      h(
          'div',
          {class: 'dialogParam2'},
          '请应考人员务必再次确认手机及其他电子设备己处于关闭状态，并请按照监考要求存放在指定位置。'
      )
    ]
)

const handleClickInfo = () => {
  Modal.info({
    title: '特别提示',
    content: vnode,
    width: '688px',
  });
};

onMounted(() => {
  startTimer();
  handleClickInfo();
})

function goBack(){
  router.go(-1)
}
function moveToNext(){
  router.push({
    name: 'UserInfo',
  })
}
function startTimer() {
  let id = setInterval(function () {
    if (countDown.value > 0){
      countDown.value--;
    }else {
      disabled.value = false
      btnBackground = "#1F63FB"
      clearInterval(id)
    }
  }, 1000);
}

const principle = [
  {index:'第一条',content: '考前60分钟，应试人员应到达本专业资格考试科目所指定的考点，凭本人准考证和本人有效证件（居民身份证、社会保障卡、军官证、士兵证、港澳台居民身份证明、外籍人员护照、外国人永久居留证明、境外永久居留证，下同）原件进入考点。'},
  {index:'第二条',content: '应试人员在每场考试前40分钟凭本人准考证和本人有效证件进入指定的考场参加考试，应试人员应如实在“考场签到表”上签到，经监考人员同意后进入考场，应试人员必须对号入座，并将准考证和有效证件放在考桌右上角，以备核对。应试人员应服从考场工作人员的管理，积极配合考场工作人员的各项监督和检查。'},
  {index:'第三条',content: '按照《人力资源社会保障部关于加强专业技术人员资格考试安全工作的通知》（人社部发〔2016〕64号）规定，开考5分钟后应试人员一律禁止入场。专业技术人员资格考试原则上封闭2个小时，不足2小时的全程封闭。'},
  {index:'第四条',content: '应试人员只准携带黑色签字笔、钢笔、2B铅笔、削笔刀、橡皮、直尺进入考场，不得携带任何书籍、笔记、纸张、计算器、报刊、手机、电子设备及通讯工具等考试中禁止使用的物品进入考场。开考后不得相互借用文具。'},
  {index:'第五条',content: '应考人员入座后，应当将准考证和身份证件放置在座位左上角，以备监考人员检查。准考证正反两面不得涂改，背面必须为空白，伪造或者涂改准考证将依照违规行为处理办法进行处理。'},
  {index:'第六条',content: '应考人员入座后，不得擅自离开考场。如果有特殊情况需要暂时离开考场，应当由监考人员陪同，返回考场时应当重新拍照。在同一考场同一时间，只允许1名应考人员暂时离开考场。'},
  {index:'第七条',content: '入座后考试开始前，应考人员可以登录计算机考试界面，核对考试相关信息。'},
  {index:'第八条',content: '应考人员由于答题需要可以向监考人员申请草稿纸。'},
  {index:'第九条',content: '如果出现考试机故障、网络故障或供电故障等异常情况，导致应考人员无法正常考试，应考人员应当听从监考人员的安排。'},
  {index:'第十条',content: '因考试机故障等客观原因导致应考人员答题时间出现损失，应考人员可以当场向监考人员提出补时要求，由监考人员根据规定予以处理。'},
  {index:'第十一条',content: '如果应考人员因突发疾病不能继续考试的，应当停止考试，立即就医。'},
  {index:'第十二条',content: '考试结束前，应考人员有违规行为或特殊情况需离场处理的，应当经监考人员批准，在《考场情况记录表》中填写交卷时间并签字确认，由指定的监考人员陪同至考站备用休息室，至考试结束前30分钟方可离开。'},
  {index:'第十三条',content: '考试结束时，应考人员应当听从监考人员指令，停止答题，将草稿纸整理好放在桌面上，等候监考人员清点回收。监考人员宣布退场后，应考人员方可退出考场。应考人员离开考场后不得在考场附近逗留、喧哗。'},
  {index:'第十四条',content: '应试人员应自觉维护考试工作场所秩序，服从考试工作人员管理，遵守考场纪律。违纪违规者，按照《专业技术人员资格考试违纪违规行为处理规定》（人力资源和社会保障部令第31号）严肃处理。'},
]
</script>