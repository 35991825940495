import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue';
import App from './App.vue';
import '@arco-design/web-vue/dist/arco.css';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import { createRouter, createWebHashHistory} from 'vue-router'
import SelectSubject from "@/pages/SelectSubject.vue";
import LoginView from "@/pages/LoginView.vue";
import TipsView from "@/pages/TipsView.vue";
import MockPrinciple from "@/pages/MockPrinciple.vue";
import OperationInstruction from "@/pages/OperationInstruction.vue";
import UserInfo from "@/pages/UserInfo.vue";
import SpecialTips from "@/pages/SpecialTips.vue";
import MockMenu from "@/pages/MockMenu.vue";
import MockExercise from "@/pages/MockExercise.vue";
import MockEnd from "@/pages/MockEnd.vue";

const history = createWebHashHistory()
const router = createRouter({
    history,
    routes: [
        {
            path: '/',
            name: 'Home',
            component: TipsView
        },
        {
            path: '/LoginView',
            name: 'LoginView',
            component: LoginView
        },
        {
            path: '/SelectSubject',
            name: 'SelectSubject',
            component: SelectSubject
        },
        {
            path: '/TipsView',
            name: 'TipsView',
            component: TipsView
        },
        {
            path: '/MockPrinciple',
            name: 'MockPrinciple',
            component: MockPrinciple
        },
        {
            path: '/OperationInstruction',
            name: 'OperationInstruction',
            component: OperationInstruction
        },
        {
            path: '/UserInfo',
            name: 'UserInfo',
            component: UserInfo
        },
        {
            path: '/SpecialTips',
            name: 'SpecialTips',
            component: SpecialTips
        },
        {
            path: '/MockMenu',
            name: 'MockMenu',
            component: MockMenu
        },
        {
            path: '/MockExercise',
            name: 'MockExercise',
            component: MockExercise
        },
        {
            path: '/MockEnd',
            name: 'MockEnd',
            component: MockEnd
        },
    ]
})
const app = createApp(App);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(router);
app.mount('#app');

