<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow-x: hidden;overflow-y: visible">
    <div id="operationInstruction">
      <h1 class="pageTitle-oi">机考操作说明</h1>
      <div class="content-oi">
        <div class="inputContainer-oi">
          <span class="inputTitle-oi">点击“继续”按钮进入考试阶段，考试计时开始。</span>
          <a-divider :margin="0"></a-divider>
          <div style="display: flex;width: 1200px;height: 751px;">
            <a-scrollbar style="width: 1004px;height: 751px;overflow-x: hidden;overflow-y: visible">
              <div class="instruction">
                <p id="start">重要事项</p>
                <p id="middle">主观题的资料区以及作菩区的工具栏使用方法可以参考答题辅助功能使用说明。此外，为方便考生作答，答题时使用键盘直接输入数字或符号，以及使用快捷键功能，系统都可接受，作答结果不受影响。</p>
                <p id="end">主观题的资料区以及作菩区的工具栏使用方法可以参考答题辅助功能使用说明。此外，为方便考生作答，答题时使用键盘直接输入数字或符号，以及使用快捷键功能，系统都可接受，作答结果不受影响。</p>
              </div>
            </a-scrollbar>
            <a-anchor style="margin-top: 32px;flex-grow: 1;" :change-hash="false">
              <a-anchor-link href="#start">重要事项</a-anchor-link>
              <a-anchor-link href="#middle">机考系统布局介绍</a-anchor-link>
              <a-anchor-link href="#end">答题界面介绍</a-anchor-link>
            </a-anchor>
          </div>
          <a-divider :margin="0"></a-divider>
          <div class="buttonBlock-oi">
            <a-button style="width: 178px;height: 36px" @click="goBack">返回</a-button>
            <a-button style="width: 178px;height: 36px;background-color: #165DFF;color: #FFFFFF;margin-left: 60px;" @click="moveToNext">继续</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-scrollbar>
</template>

<style>
#operationInstruction {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  padding-bottom: 40px;
  margin: 0 auto;
  display: flex;
  //background: linear-gradient(#1F63FB,#428DFF);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: url("../assets/back.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.pageTitle-oi{
  margin-top: 32px;
  font-size: 32px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 45px;
  text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
}
.content-oi{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.inputContainer-oi{
  margin-top: 32px;
  width: 1200px;
  height: 875px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inputTitle-oi{
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #F53F3F;
  line-height: 24px;
}
.instruction{
  display: flex;
  flex-direction: column;
  padding: 32px;
  overflow: hidden;
}
.buttonBlock-oi{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script setup>
import {useRouter} from "vue-router";

const router = useRouter()

function goBack(){
  router.push({
    name: 'MockPrinciple',
  })
}
function moveToNext(){
  router.push({
    name: 'UserInfo',
  })
}
</script>