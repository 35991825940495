<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto;">
    <div id="loginView">
      <div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 82px;background-color: #2E72B8;">
        <div style="flex-grow: 1;"/>
        <a-image :src="require('../assets/img_logo_ks_normal.png')"/>
        <h1 class="pageTitle-login">全国计算机技术与软件专业技术资格(水平)考试机考模拟系统</h1>
        <div style="flex-grow: 1;"/>
        <div class="top-right-corner">机号1</div>
      </div>

      <div style="flex-grow: 1;"/>

      <div class="content-login">
        <div class="inputContainer-login">
          <span class="inputTitle-login">请输入准考证号</span>
          <a-space :size="12">
            <a-input :style="{width:'320px'}" placeholder="请在输入框中输入练习准考证号“1234”" allow-clear v-model="input" />
            <a-button type="primary" @click="login">登录</a-button>
          </a-space>
        </div>
      </div>

      <div style="flex-grow: 3;"/>

      <div class="footer">
        <span style="font-size: 14px;font-weight: 400;color: #4E5969;">郑重声明：练习试卷旨在让考生熟悉机考环境，试题类型和作答方式，正式考试试卷的试题类型、试题数量、分值分布和机考界面等，可能会做适当调整。</span>
        <div class="stamp-1">
          <a-image width="92" height="92" :src="require('../assets/downlaod.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">下载【软考通】APP</span>
        </div>
        <div class="stamp-2">
          <a-image width="92" height="92" :src="require('../assets/official.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">关注公众号领资料大全</span>
        </div>
      </div>
    </div>
  </a-scrollbar>
</template>

<style>
#loginView {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  //background: linear-gradient(#1F63FB,#428DFF);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background: url("../assets/back.png") no-repeat;
  background-size: 100% 100%;
}
.pageTitle-login{
  margin-left: 13px;
  font-size: 35px;
  font-weight: 600;
  color: #FFFFFF;
  min-width: 936px;
}
.content-login{
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  //justify-content: center;
}
.inputContainer-login{
  //margin-top: 128px;
  width: 688px;
  height: 164px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer{
  width: 100vw;
  display: flex;
  //margin-top: 460px;
  margin-bottom: 40px;
  justify-content: flex-end;
  align-items: flex-end;
}
.inputTitle-login{
  margin-top: 36px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  text-align: center;
}
.stamp-1{
  margin-left: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stamp-2{
  margin-right: 44px;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-right-corner{
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  margin-right: 44px;
  min-width: 60px;
}
</style>
<script setup>
import {useRouter} from "vue-router";
import {ref} from "vue";
import {Message} from "@arco-design/web-vue";

const router = useRouter()

const input = ref('')

function login(){
  if (input.value !== '1234'){
    Message.info('准考证号不正确 请输入1234开始模拟考试')
    return
  }
  router.push({
    name: 'SelectSubject',
  })
}
</script>