<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto;">
    <div id="mockMenu">
      <div class="header">
        <div style="font-size: 18px;font-weight: 400;color: #FFFFFF;margin-left: 20px;">注册会计师全国同意考试练习试卷</div>
        <div style="flex-grow: 1;"></div>
        <div style="font-size: 16px;font-weight: 500;color: #FFFFFF;">已完成0/41题</div>
        <div class="countdown-container">剩余：00：58：29</div>
        <div class="lock-button"><icon-export style="margin-right: 5px"/>暂离锁屏</div>
        <div style="font-size: 16px;font-weight: 500;color: #FFFFFF;margin-left: 16px;">座位号：</div>
        <div style="
        width: 24px;height: 24px;font-size: 14px;
        font-weight: 600;color: #165DFF;
        background-color: #FFFFFF;
        margin-left: 4px;margin-right: 16px;
        display: flex;justify-content: center;align-items: center;">1</div>
      </div>
      <div class="content">
        <div class="menu-container">
          <div style="font-size: 32px;font-weight: 400;color: #1D2129;margin-top: 24px;line-height: 46px;">目录</div>
          <div style="font-size: 16px;font-weight: 400;color: #4E5969;margin-bottom: 24px;">（考试分为以下四个部分）</div>
          <a-divider :margin="0"></a-divider>

          <div v-for="(item,index) in data" :key="index" class="item-container-out">
            <div class="item-container">
              <div class="item-text-container">
                <div style="font-size: 24px;font-weight: 500;line-height: 32px">
                  {{ item.title }}
                </div>
                <div style="font-size: 14px;font-weight: 400;line-height: 22px;margin-top: 12px">
                  {{ item.content }}
                </div>
              </div>
              <a-button style="width: 118px;height: 36px;border-radius: 2px 2px 2px 2px;
              border: 1px solid #165DFF;background-color: #FFFFFF;
              font-size: 14px;font-weight: 400;color: #165DFF;margin-right: 13px">点击进入<icon-right style="margin-left: 8px"/></a-button>
            </div>
            <a-divider :margin="0"></a-divider>
          </div>
          <div style="width: 940px;height: 24px;font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;margin-top: 16px;margin-bottom: 16px;">
            注意：本场考试时间为<span style="color: #F53F3F">120分钟</span>
          </div>

          <div class="buttonBlock-mn">
            <a-button style="width: 178px;height: 36px" @click="goBack">返回</a-button>
            <a-button style="width: 178px;height: 36px;background-color: #165DFF;color: #FFFFFF;margin-left: 60px;" @click="moveToNext">继续</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-scrollbar>
</template>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter()

function goBack(){
  router.push({
    name: 'SpecialTips',
  })
}
function moveToNext(){
  router.push({
    name: 'MockMenu',
  })
}

const data = [
  {
    title: "一、单项选择题",
    content: "本题型共25小题，每小题1分，共25分。每小题只有一个正确答案，请从每小题的备选答案中选出一个你认为正确的答案，用鼠标点击相应的选项",
  },
  {
    title: "二、多项选择题",
    content: "本题型共10小题，每小题2分，共20分。每小题均有多个正确答案，请从每小题的备选答案中选出你认为正确的答案，用鼠标点击相应的选项。每小题所有答案选择正确的得分，不答、错答、漏答均不得分。",
  },
  {
    title: "三、计算分析题",
    content: "本题型共5小题36分。 其中一道小题可以选用中文成英文解答，请仔细阅读答题要求。如使用英文解答，须全部使用英文，答题正确的，增加5分。本题型最高得分为41分。",
  },
  {
    title: "四、综合题",
    content: "本题共19分。",
  },
]
</script>

<style>
#mockMenu {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  background-color: #F7F8FA;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}
.menu-container{
  margin-top: 40px;
  margin-bottom: 78px;
  width: 1200px;
  height: auto;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item-container-out{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item-container{
  width: 940px;
  height: 152px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
}
.item-text-container{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  margin-right: 40px;
}
.buttonBlock-mn{
  height: 84px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>