<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto;">
    <div id="tipsView">
      <div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 82px;background-color: #2E72B8;">
        <div style="flex-grow: 1;"/>
        <a-image :src="require('../assets/img_logo_head_normal.png')" width="40" height="40"/>
        <h1 class="pageTitle-login">全国计算机技术与软件专业技术资格(水平)考试机考模拟系统</h1>
        <div style="flex-grow: 1;"/>
        <div class="top-right-corner">机号1</div>
      </div>

      <div style="flex-grow: 1;"/>

      <div class="content-tips">
        <div class="inputContainer-tips" :style="{height: contentHeight + 'px'}">
          <div style="display: flex;justify-content: center;align-items: center;width: 100%;margin-top: 36px;margin-bottom: 32px;">
            <icon-info-circle-fill style="color: #165DFF;margin-right: 4px"/>
            <span class="inputTitle-tips">温馨提示</span>
          </div>

          <a-scrollbar :style="{width: '100%',height: contentHeight - 152 + 'px',overflowX: 'hidden',overflowY: 'visible'}">
            <a-space direction="vertical" :size="12">
              <div class="tipsContent-light">本机考模拟系统帮助考生了解计算机技术与软件专业技术资格（水平）考试机考模式下的系统界面、作答过程的仿真形式。应试人员可通过本系统熟悉考试作答界面、考试流程和计算器等功能的使用。</div>
              <div class="tipsContent-light">本系统是软考《综合知识》、《案例分析》和《论文》的作答过程展示。本系统中各科目试卷结构、题目数量、试题长度及难度等均不具实际参考意义。</div>
              <div class="tipsContent">本系统不含解析、评分等功能，如需使用【历年真题题库】【随机组卷模考评分】【知识点详解】及【精准解析】等完整功能，您可扫描右下方二维码，下载【软考通】APP开启免费刷题备考体验</div>
              <a-image :src="require('../assets/img_home_appstore_normal.png')" style="margin-left: 32px" :width="461" :height="240"/>
              <div class="tipsContent">本系统支持Chrome、Safari、Firefox、Edge等浏览器访问。</div>
            </a-space>
          </a-scrollbar>

          <a-button type="primary" @click="confirm" style="width: 108px;height: 32px;
          background: #165DFF;border-radius: 2px 2px 2px 2px;margin-bottom: 32px;margin-top: 24px;">
            开始模拟
          </a-button>
        </div>
      </div>

      <div style="flex-grow: 1.4;"/>

      <div class="footer-tips">
        <div style="display: flex;flex-direction: column;">
          <div style="color: #1D2129;font-size: 14px;font-weight: 500;">公司信息</div>
          <a-space :size="10" style="color: #4E5969;font-size: 14px;margin-top: 15px" direction="vertical">
            <div>公司信息 杭州刻学科技有限公司</div>
            <div>地址 浙江省杭州市余杭区仓前街道向往街 1008号14幢702室</div>
            <div>备案号：<a-link :hoverable="false" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #4E5969">浙ICP备2021004407号-1</a-link></div>
          </a-space>
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 70px;margin-right: 174px">
          <div style="color: #1D2129;font-size: 14px;font-weight: 500;">联系方式</div>
          <a-space :size="10" style="color: #4E5969;font-size: 14px;margin-top: 15px" direction="vertical">
            <div>反馈热线 公司QQ-3577389473</div>
            <div>商务邮箱 wei.wu@ili-inc.com</div>
          </a-space>
        </div>
        <div class="stamp-1">
          <a-image width="92" height="92" :src="require('../assets/downlaod.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #1D2129;margin-top: 12px">下载【软考通】APP</span>
        </div>
        <div class="stamp-2">
          <a-image width="92" height="92" :src="require('../assets/official.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #1D2129;margin-top: 12px">关注公众号领资料大全</span>
        </div>
      </div>
    </div>
  </a-scrollbar>
</template>

<style>
#tipsView {
  width: 100vw;
  //height: 100vh;
  min-height: 100vh;
  min-width: 1440px;
  margin: 0 auto;
  display: flex;
  //background: linear-gradient(#1F63FB,#428DFF);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  //overflow: hidden;
  background: url("../assets/back.png") no-repeat;
  background-size: 100% 100%;
  //position: relative;
}
.pageTitle-tips{
  margin-top: 8px;
  width: 930px;
  font-size: 45px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}
.content-tips{
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  //justify-content: center;
}
.inputContainer-tips{
  margin-top: 20px;
  //margin-bottom: 80px;
  width: 800px;
  height: auto;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.footer-tips{
  margin-top: 40px;
  //position: absolute;
  //bottom: 0;
  //right: 0;
  //background: linear-gradient(#DEDFE2,#B5B6BA);
  width: 100vw;
  min-width: 1400px;
  display: flex;
  //margin-top: 253px;
  //padding-bottom: 40px;
  margin-bottom: 20px;
  justify-content: center;
  //align-items: flex-end;
}
.inputTitle-tips{
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  text-align: center;
}
.tipsContent-light{
  margin-left: 32px;
  margin-right: 32px;
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  line-height: 22px;
}
.tipsContent{
  margin-left: 32px;
  margin-right: 32px;
  width: 624px;
  font-size: 14px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}
</style>
<script setup>
import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";

let contentHeight = ref(0)

function pageView(title) {
  aplus_queue.push({
    'action': 'aplus.record',
    'arguments': ['pageview', 'EXP', {
      title: title,
      client_code: '软考通机考模拟系统',
      // app_code: 'com.softwarepc.web',
      // app_version: '1.0.0',
    }]
  });
}

function button_click(element_content) {
  aplus_queue.push({
    'action': 'aplus.record',
    'arguments': ['button_click', 'CLK', {
      element_content: element_content,
      client_code: '软考通机考模拟系统',
      // app_code: 'com.softwarepc.web',
      // app_version: '1.0.0',
    }]
  });
}

window.onresize = function (){
  if (window.innerHeight - 290 >= 594){
    contentHeight.value = 594
  }else{
    contentHeight.value = window.innerHeight - 290
    // contentHeight.value = 594
  }
}

onMounted(() => {
  if (window.innerHeight - 290 >= 594){
    contentHeight.value = 594
  }else{
    contentHeight.value = window.innerHeight - 290
    // contentHeight.value = 594
  }
  pageView('首页页面')
})

const router = useRouter()
function confirm(){
  button_click('开始模拟')
  router.push({
    name: 'LoginView',
  })
}
</script>