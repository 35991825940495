<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto">
    <div id="selectSubject">
      <div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 82px;background-color: #2E72B8;">
        <div style="flex-grow: 1;"/>
        <a-image :src="require('../assets/img_logo_ks_normal.png')"/>
        <h1 class="pageTitle-login">全国计算机技术与软件专业技术资格(水平)考试机考模拟系统</h1>
        <div style="flex-grow: 1;"/>
        <div class="top-right-corner">机号1</div>
      </div>

      <div class="content-ss">
        <div class="inputContainer">
          <span class="inputTitle">请选择需要练习的科目</span>
          <div class="selectBlock">
            <a-tabs default-active-key="1" style="width: 624px;margin-right: 32px;margin-left: 32px;">
              <a-tab-pane key="1" title="高级">
                <div class="selectPanel">
                  <a-space :size="20" v-for="(items,index) in data3" :key="index" style="margin-top: 20px;">
                    <a-button v-for="(item,indexB) in items" :key="indexB" class="button" @click="goToTipsView(item.title)">{{item.title}}</a-button>
                  </a-space>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" title="中级">
                <div class="selectPanel">
                  <a-space :size="20" v-for="(items,index) in data2" :key="index" style="margin-top: 20px;">
                    <a-button v-for="(item,indexB) in items" :key="indexB" class="button" @click="goToTipsView(item.title)">{{item.title}}</a-button>
                  </a-space>
                </div>
              </a-tab-pane>
              <a-tab-pane key="3" title="低级">
                <div class="selectPanel">
                  <a-space :size="20" v-for="(items,index) in data1" :key="index" style="margin-top: 20px;">
                    <a-button v-for="(item,indexB) in items" :key="indexB" class="button" @click="goToTipsView(item.title)">{{item.title}}</a-button>
                  </a-space>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <span class="highlight">完整功能，您可扫描右下方二维码，下载【软考通】APP开启免费刷题备考体验</span>
          <span class="normalTips">机考模拟练习系统要求计算机浏览器为Edge87及以上，<br>Chrome 87及以上，Firefox 80及以上，Mac Safari 12.0及以上或其他对应内核的浏览器，<br>屏幕显示分辦率为1024x768以上</span>
        </div>
      </div>
      <div class="footer-select-subject">
        <span style="margin-right: 344px;font-size: 14px;font-weight: 400;color: #4E5969;">郑重声明：练习试卷旨在让考生熟悉机考环境，试题类型和作答方式，正式考试试卷的试题类型、试题数量、分值分布和机考界面等，可能会做适当调整。</span>
<!--        <div class="stamp-1">-->
<!--          <a-image width="92" height="92" :src="require('../assets/downlaod.jpg')"></a-image>-->
<!--          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">下载【软考通】APP</span>-->
<!--        </div>-->
<!--        <div class="stamp-2">-->
<!--          <a-image width="92" height="92" :src="require('../assets/official.jpg')"></a-image>-->
<!--          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">关注公众号领资料大全</span>-->
<!--        </div>-->
      </div>

      <div style="position: absolute;bottom: 0;right: 0;display: flex;margin-bottom: 40px;">
        <div class="stamp-1">
          <a-image width="92" height="92" :src="require('../assets/downlaod.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">下载【软考通】APP</span>
        </div>
        <div class="stamp-2">
          <a-image width="92" height="92" :src="require('../assets/official.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">关注公众号领资料大全</span>
        </div>
      </div>

    </div>
  </a-scrollbar>
</template>

<style>
#selectSubject {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  min-width: 1400px;
  //background: linear-gradient(#1F63FB,#428DFF);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background: url("../assets/back.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.pageTitle{
  margin-top: 8px;
  width: 930px;
  font-size: 45px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
}
.content-ss{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.inputContainer{
  margin-top: 36px;
  //margin-bottom: 80px;
  width: 688px;
  height: auto;
  min-height: 500px;
  padding-bottom: 36px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inputTitle{
  margin-top: 36px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  text-align: center;
}
.button{
  width: 195px;
  height: 36px;
}
.selectBlock{
  width: 688px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}
.selectPanel{
  display: flex;
  flex-direction: column;
}
.highlight{
  margin-top: 36px;
  font-size: 12px;
  font-weight: 400;
  color: #F53F3F;
  line-height: 20px;
}
.normalTips{
  width: 500px;
  height: 51px;
  font-size: 11px;
  font-weight: 400;
  color: #86909C;
  line-height: 17px;
  text-align: center;
}
.footer-select-subject{
  //position: absolute;
  //bottom: 0;
  //right: 0;
  width: 100vw;
  min-width: 1400px;
  display: flex;
  //margin-top: 169px;
  margin-bottom: 40px;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter()
function splitArrayIntoGroups(arr, groupSize) {
  let result = [];
  for (let i = 0; i < arr.length; i += groupSize) {
    result.push(arr.slice(i, i + groupSize));
  }
  return result;
}

function goToTipsView(title){
  sessionStorage.setItem("subjectName",title)
  button_click(title)
  router.push({
    name: 'MockPrinciple',
  })
}

function button_click(element_content) {
  aplus_queue.push({
    'action': 'aplus.record',
    'arguments': ['button_click', 'CLK', {
      element_content: element_content,
      client_code: '软考通机考模拟系统',
      // app_code: 'com.softwarepc.web',
      // app_version: '1.0.0',
    }]
  });
}

const dataList1 = [
  {title: '信息处理技术员', isSelected: false},
  {title: '网络管理员', isSelected: false},
  {title: '程序员', isSelected: false}
]
const dataList2 = [
  {title: '系统集成项目管理工程师', isSelected: false},
  {title: '信息系统管理工程师', isSelected: false},
  {title: '数据库系统工程师', isSelected: false},
  {title: '嵌入式系统设计师', isSelected: false},
  {title: '多媒体应用设计师', isSelected: false},
  {title: '电子商务设计师', isSelected: false},
  {title: '信息安全工程师', isSelected: false},
  {title: '信息系统监理师', isSelected: false},
  {title: '软件设计师', isSelected: false},
  {title: '网络工程师', isSelected: false},
  {title: '软件评测师', isSelected: false}
]
const dataList3 = [
  {title: '信息系统项目管理师', isSelected: false},
  {title: '系统规划与管理师', isSelected: false},
  {title: '系统架构设计师', isSelected: false},
  {title: '网络规划设计师', isSelected: false},
  {title: '系统分析师', isSelected: false}
]

const data1 = splitArrayIntoGroups(dataList1,3)
const data2 = splitArrayIntoGroups(dataList2,3)
const data3 = splitArrayIntoGroups(dataList3,3)
</script>

<script>
export default {
  data(){
    return {

    };
  }
}
</script>