<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto;">
    <div id="mockEnd">
      <div class="top-right-corner" style="  margin-top: 44px;align-self: flex-end;">机号1</div>
      <h1 class="pageTitle-me">考试结束，助您成功</h1>
      <div class="content-me">
        <div class="inputContainer-me">
          <span class="inputTitle-me">本系统不含解析、评分等功能，如需使用【历年真题题库】【随机组卷模考评分】【知识点详解】及【精准解析】等完整功能，您可扫描右下方二维码，下载【软考通】APP开启免费刷题备考体验</span>
          <a-image :src="require('../assets/img_home_appstore_normal.png')" style="margin-bottom: 41px" :width="536" :height="279"/>
        </div>
      </div>
      <a-button style="width: 136px;height: 40px;background-color: #165DFF;color: #FFFFFF;border-radius: 4px 4px 4px 4px;margin-top: 20px" @click="reset">
        重新模拟
      </a-button>
      <div style="flex-grow: 1"/>
      <div class="footer-end">
        <span style="font-size: 14px;font-weight: 400;color: #4E5969;">郑重声明：练习试卷旨在让考生熟悉机考环境，试题类型和作答方式，正式考试试卷的试题类型、试题数量、分值分布和机考界面等，可能会做适当调整。</span>
        <div class="stamp-1">
          <a-image width="92" height="92" :src="require('../assets/downlaod.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">下载【软考通】APP</span>
        </div>
        <div class="stamp-2">
          <a-image width="92" height="92" :src="require('../assets/official.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">关注公众号领资料大全</span>
        </div>
      </div>
    </div>
  </a-scrollbar>
</template>

<style>
#mockEnd {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  //background: linear-gradient(#1F63FB, #428DFF);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background: url("../assets/back.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.pageTitle-me {
  margin-top: 8px;
  font-size: 45px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 65px;
  text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
}

.content-me {
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  justify-content: center;
}

.inputContainer-me {
  //margin-top: 80px;
  width: 688px;
  height: auto;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputTitle-me {
  margin: 40px 32px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #1D2129;
  text-align: center;
}
.footer-end{
   width: 100vw;
   display: flex;
 //margin-top: 169px;
   margin-bottom: 40px;
   justify-content: flex-end;
   align-items: flex-end;
 }
</style>

<script setup>
import {useRouter} from "vue-router";
import {onMounted} from "vue";

const router = useRouter()

onMounted(() => {
  pageView('考试结束页面')
})

function reset(){
  button_click('重新模拟')
  router.push({
    name: 'TipsView',
  })
}
function pageView(title) {
  aplus_queue.push({
    'action': 'aplus.record',
    'arguments': ['pageview', 'EXP', {
      title: title,
      client_code: '软考通机考模拟系统',
      // app_code: 'com.softwarepc.web',
      // app_version: '1.0.0',
    }]
  });
}

function button_click(element_content) {
  aplus_queue.push({
    'action': 'aplus.record',
    'arguments': ['button_click', 'CLK', {
      element_content: element_content,
      client_code: '软考通机考模拟系统',
      // app_code: 'com.softwarepc.web',
      // app_version: '1.0.0',
    }]
  });
}
</script>