<template>
  <a-scrollbar style="overflow-y: visible;overflow-x: hidden;width: 100vw;height: 100vh">
    <div id="mockExercise">
      <div class="header-ex">
        <div style="font-size: 18px;font-weight: 400;color: #FFFFFF;margin-left: 20px;">全国计算机技术与软件专业技术资格(水平)考试机考模拟系统
        </div>
        <div style="flex-grow: 1;"></div>
        <div style="font-size: 16px;font-weight: 500;color: #FFFFFF;">已完成{{ doneNum }}/{{ exerciseList_packed.length }}题</div>
        <div class="countdown-container">
          剩余：<a-countdown id="countdown" :value-style="{fontSize: '14px',color: '#FFFFFF'}"
                            :value="restTime" :now="dateNow" @finish="timeOut"/>
        </div>
        <div style="font-size: 16px;font-weight: 500;color: #FFFFFF;margin-left: 16px;">座位号：</div>
        <div style="
        width: 24px;height: 24px;font-size: 14px;
        font-weight: 600;color: #165DFF;
        background-color: #FFFFFF;
        margin-left: 4px;margin-right: 16px;
        display: flex;justify-content: center;align-items: center;">1
        </div>
      </div>
      <div class="content-ex" :style="{height: contentHeight}">
        <div style="display: flex;flex-direction: column;width: 196px;height: auto;">
          <div
              style="display: flex;flex-direction: column;align-items: center;width: 100%;height: 284px;background-color: #FFFFFF;padding-bottom: 12px">
            <a-image :src="require('../assets/img_avatar_small_normal.png')" :width="120" :height="120" style="margin-top: 16px;"></a-image>
            <div style="width: 196px;height: 108px;display: flex;margin-top: 28px;">
              <div style="width: 60px;height: 108px;color: #86909C;font-size: 12px;font-weight: 400;line-height: 20px;margin-left: 16px">
                <div>姓名</div>
                <div>性别</div>
                <div>准考证号</div>
                <div>证件号码</div>
                <div>考试科目</div>
              </div>
              <div
                  style="height: 108px;color: #1D2129;font-size: 12px;font-weight: 400;line-height: 20px;margin-left: 16px;flex-grow: 1;">
                <div>演示考生</div>
                <div>男</div>
                <div>1234</div>
                <div>1234567890</div>
                <div>{{ subjectName }}</div>
              </div>
            </div>
          </div>
          <div
              style="display: flex;flex-direction: column;align-items: flex-start;width: 100%;background-color: #FFFFFF;flex-grow: 1;margin-top: 2px">
            <div
                style="margin-left: 16px;font-size: 14px;font-weight: 500;color: #165DFF;line-height: 22px;margin-top: 12px;margin-bottom: 12px;">
              {{ subjectName }}
            </div>
            <a-scrollbar class="left-scrollbar" :style="{height: leftHeight}">
              <a-collapse v-for="(item,index) in exerciseTypeList" :key="index" :default-active-key="[1,2,3]"
                          class="collapse-container" :bordered="false">
                <template #expand-icon="{ active }">
                  <icon-down v-if="active"/>
                  <icon-up v-else/>
                </template>
                <a-collapse-item :header="item[0].typeExplain" :key="index+1">
                  <div style="width: 196px;display: flex;flex-wrap: wrap;height: auto;background-color: #FFFFFF;
                  padding-left: 14px;padding-top: 12px;margin-left: -34px;margin-top: -10px;margin-bottom: -10px">
                    <div class="collapse-content" :class="{'collapse-content-done': exerciseList_packed[item_inner.index].isDone,
                    'collapse-content-local': localIndex === item_inner.index}"
                         v-for="(item_inner,index_inner) in item" :key="index_inner" @click="changeExercise(item_inner.index)">
                      {{ item_inner.index + 1 }}
                      <div class="triangle" v-if="item_inner.isMarked"/>
                    </div>
                  </div>
                </a-collapse-item>
              </a-collapse>
            </a-scrollbar>
            <div style="flex-grow: 1"></div>
            <div style="height: 48px;width: 100%;display: flex;justify-content: center">
              <div class="left-icons">
                <div style="width: 16px;height: 16px;background: #165DFF;border-radius: 4px 4px 4px 4px;"></div>
                <div style="font-size: 12px;color: #4E5969;line-height: 20px;">当前</div>
              </div>
              <div class="left-icons">
                <div
                    style="width: 16px;height: 16px;background: #FFFFFF;border-radius: 2px 2px 2px 2px;border: 1px solid #D8D8D8;"></div>
                <div style="font-size: 12px;color: #4E5969;line-height: 20px;">未完成</div>
              </div>
              <div class="left-icons">
                <div
                    style="width: 16px;height: 16px;background: #00B42A;border-radius: 4px 4px 4px 4px;opacity: 0.7;"></div>
                <div style="font-size: 12px;color: #4E5969;line-height: 20px;">已完成</div>
              </div>
              <div class="left-icons">
                <div style="width: 16px;height: 16px;background: #FFFFFF;border-radius: 2px 2px 2px 2px;border: 1px solid #D8D8D8;
                display: flex;justify-content: flex-end;align-items: flex-end;position: relative;">
                  <div class="triangle"></div>
                </div>
                <div style="font-size: 12px;color: #4E5969;line-height: 20px;">标记</div>
              </div>
            </div>
          </div>
        </div>

        <div class="single-multiple" v-if="exercise_now.type === '单项选择题'">
          <div style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;">
            {{ exercise_now.typeExplain }}
          </div>
          <a-divider :margin="0" style="margin-top: 16px"></a-divider>
          <div style="display: flex;align-items: flex-start;margin-top: 16px;flex-grow: 1;overflow: auto">
            <div style="width: 24px;height: 24px;background: #E8F3FF;border-radius: 2px 2px 2px 2px;
            display: flex;justify-content: center;align-items: center;
            font-size: 14px;font-weight: 600;color: #165DFF;line-height: 22px;">
              {{ exercise_now.index + 1 }}
            </div>
            <div style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;margin-left: 4px;"
                 v-html="exercise_now.questionContent"></div>
          </div>

          <a-radio-group v-model="radioAnswer" direction="vertical" @change="onOptionChange">
            <a-radio value="A">
              <span style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;">A</span>
              <span v-html="exercise_now.questionA"
                    style="margin-left: 12px;font-size: 16px;color: #1D2129;line-height: 24px;"></span>
            </a-radio>
            <a-radio value="B">
              <span style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;">B</span>
              <span v-html="exercise_now.questionB"
                    style="margin-left: 12px;font-size: 16px;color: #1D2129;line-height: 24px;"></span>
            </a-radio>
            <a-radio value="C">
              <span style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;">C</span>
              <span v-html="exercise_now.questionC"
                    style="margin-left: 12px;font-size: 16px;color: #1D2129;line-height: 24px;"></span>
            </a-radio>
            <a-radio value="D">
              <span style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;">D</span>
              <span v-html="exercise_now.questionD"
                    style="margin-left: 12px;font-size: 16px;color: #1D2129;line-height: 24px;"></span>
            </a-radio>
          </a-radio-group>
        </div>

        <div class="alfx" v-if="exercise_now.type === '案例分析'">
          <a-split style="width: 100%;height: 100%;" direction="vertical">
            <template #first>
              <div style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;margin-left: 16px;margin-right: 16px;margin-top: 16px">
                {{ exercise_now.typeExplain }}
              </div>
              <a-divider :margin="0" style="margin-top: 16px;margin-left: 16px;margin-right: 16px"></a-divider>
              <div style="font-size: 20px;color: #1D2129;line-height: 28px;margin-left: 16px;margin-top: 16px">
                资料区
              </div>
              <div class="question-container" :style="{height: contentHeight/2 + 'px'}">
                <div style="width: 24px;height: 24px;background: #E8F3FF;border-radius: 2px 2px 2px 2px;
            display: flex;justify-content: center;align-items: center;
            font-size: 14px;font-weight: 600;color: #165DFF;line-height: 22px;">
                  {{ exercise_now.index + 1 }}
                </div>
                <div style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;margin-left: 4px;"
                     v-html="exercise_now.questionContent"></div>
              </div>
            </template>

            <template #second>
              <div :style="{maxHeight: contentHeight/2 + 'px',padding: '16px'}">
                <div style="height: 100%;display: flex;flex-direction: column;">
                  <div style="font-size: 20px;color: #1D2129;line-height: 28px;">
                    资料区
                  </div>
                  <div style="flex-grow: 1;">
                    <a-tabs type="line" style="margin-top: 16px">
                      <a-tab-pane style="margin-bottom: 16px;"
                                  v-for="(item,index) in exercise_now.question" :key="index" :title="'问题' + numberToChinese(index+1)">
                        <div style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;"
                             v-html="item"></div>
                        <a-textarea placeholder="本机考模拟系统仅供应试人员熟悉考试作答界面、考试流程和计算器等功能的使用。将不对您的作答进行留存、评分。
如需查看【全部历年真题】及【免费专业解析】等完整功能，您可在交卷后扫描页面右下方二维码，下载【软考通】APP。"
                                    v-model="answerList[index]" style="margin-top: 16px;height: 200px" @change="onInput"></a-textarea>
                      </a-tab-pane>
                    </a-tabs>
                  </div>
                </div>
              </div>
            </template>
          </a-split>
        </div>

        <div class="alfx" v-if="exercise_now.type === '论文'">
          <a-split style="width: 100%;height: 100%;" direction="vertical">
            <template #first>
              <div style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;margin-left: 16px;margin-right: 16px;margin-top: 16px">
                {{ exercise_now.typeExplain }}
              </div>
              <a-divider :margin="0" style="margin-top: 16px;margin-left: 16px;margin-right: 16px"></a-divider>
              <div style="font-size: 20px;color: #1D2129;line-height: 28px;margin-left: 16px;margin-top: 16px">
                资料区
              </div>
              <div style="display: flex;align-items: flex-start;margin-top: 16px;margin-left: 16px;margin-right: 16px;flex-grow: 1;">
                <div style="width: 24px;height: 24px;background: #E8F3FF;border-radius: 2px 2px 2px 2px;
            display: flex;justify-content: center;align-items: center;
            font-size: 14px;font-weight: 600;color: #165DFF;line-height: 22px;">
                  {{ exercise_now.index + 1 }}
                </div>
                <div style="font-size: 16px;font-weight: 500;color: #1D2129;line-height: 24px;margin-left: 4px;"
                     v-html="exercise_now.questionContent"></div>
              </div>
            </template>

            <template #second>
              <div :style="{height: contentHeight/2 + 'px'}">
                <div style="display: flex;flex-direction: column;padding: 16px;height: 100%">
                  <a-textarea placeholder="本机考模拟系统仅供应试人员熟悉考试作答界面、考试流程和计算器等功能的使用。将不对您的作答进行留存、评分。
如需查看【全部历年真题】及【免费专业解析】等完整功能，您可在交卷后扫描页面右下方二维码，下载【软考通】APP。"
                              v-model="answerList[0]" style="height: 100%" @change="onInput"></a-textarea>
                </div>
              </div>
            </template>
          </a-split>
        </div>

      </div>
      <a-divider :margin="0"></a-divider>
      <div class="footer-ex">
        <a-button style="width: 180px;height: 34px;background-color: #165DFF;color: #FFFFFF;margin-left: 8px;" @click="showDialog">交卷
        </a-button>
        <div style="display: flex;margin-left: 45px" @click="mark">
          <icon-star style="color: #4E5969;" v-if="!exercise_now.isMarked"/>
          <icon-star-fill style="color: #F53F3F" v-if="exercise_now.isMarked"/>
          <div style="font-size: 14px;font-weight: 400;margin-left: 5px">
            <span style="color: #F53F3F" v-if="exercise_now.isMarked">取消标记</span>
            <soan style="color: #4E5969" v-if="!exercise_now.isMarked">标记本题</soan>
          </div>
        </div>
        <div style="flex-grow: 1;"></div>
        <div style="display: flex;align-items: center;justify-content: center;width: 74px;height: 32px;
      border-radius: 2px 2px 2px 2px;
      border: 1px solid #165DFF;
      color: #165DFF;font-weight: 400;font-size: 14px;
      margin-right: 12px" @click="showCalculator">计算器</div>
        <a-button
            @click="lastExercise"
            style="width: 96px;height: 32px;background: #165DFF;border-radius: 2px 2px 2px 2px;color: #FFFFFF;margin-right: 16px">
          <icon-double-left style="margin-right: 6px"/>
          上一题
        </a-button>
        <a-button
            @click="nextExercise"
            style="width: 96px;height: 32px;background: #165DFF;border-radius: 2px 2px 2px 2px;color: #FFFFFF;margin-right: 16px">
          下一题
          <icon-double-right style="margin-left: 6px"/>
        </a-button>
      </div>
    </div>
    <template>
      <a-modal v-model:visible="visible" :closable="false" :footer="false">
        <template #title>
          提示
        </template>
        <div style="display: flex;flex-direction: column;align-items: center;">
          <span style="font-size: 16px;color: #1D2129;font-weight: 500;">
            还有<span style="color: #FF7D00">{{ exerciseList_packed.length - doneNum }}</span>道题<span style="color: #FF7D00">未完成</span>，
            确认结束考试？
          </span>
          <a-radio v-model="confirm" style="margin-top: 16px">
            <span style="font-size: 14px;font-weight: 400;color: #1D2129;">我确认结束考试，考试后不可再次作答</span>
          </a-radio>
          <a-divider :margin="0" style="margin-top: 24px;margin-bottom: 16px;"></a-divider>
          <div class="buttonBlock-dialog">
            <a-button style="width: 108px;height: 32px;background-color: #165DFF;color: #FFFFFF;margin-right: 16px;" @click="handleOk">确认</a-button>
            <a-button style="width: 108px;height: 32px;" @click="handleCancel">取消</a-button>
          </div>
        </div>
      </a-modal>
      <a-modal v-model:visible="calculatorVisible"
               :footer="false" :width="520" :body-style="{padding:0}">
        <template #title>
          信息
        </template>
        <div style="display: flex;flex-direction: column;align-items: center;margin-bottom: 16px">
          <div style="width: 456px;height: 66px;font-size: 14px;font-weight: 500;color: #1D2129;line-height: 22px;margin-top: 24px">
            实际考试中，您将使用考试系统内置的计算器程序，该程序和Winddows操作系统自带的计算器（科学型、统计型）类似，如您希望提前熟悉相关操作，可按照以下步骤获取该程序：
          </div>
          <div style="width: 456px;height: 66px;font-size: 14px;font-weight: 500;color: #1D2129;line-height: 22px;margin-top: 10px">
            1.下载“PTCalc.zip”压缩包<br>2.解压“PTCalc.zip”压缩包<br>3.运行“PTCalc.exe”程序
          </div>
          <a-divider :margin="0" style="margin-top: 24px;margin-bottom: 16px;"></a-divider>
          <div class="buttonBlock-dialog">
            <a-button style="width: 108px;height: 32px;background-color: #165DFF;color: #FFFFFF;margin-right: 16px;" @click="downloadFile">下载</a-button>
            <a-button style="width: 108px;height: 32px;" @click="calculatorClose">取消</a-button>
          </div>
        </div>
      </a-modal>
    </template>
  </a-scrollbar>
</template>

<script setup>
import axios from "axios";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {Message} from "@arco-design/web-vue";

const subjectName = sessionStorage.getItem("subjectName")

const dateNow = Date.now()
let restTime = dateNow + 1000 * 60 * 60 * 6

let exerciseList
let exerciseList_packed = ref([])
let exerciseTypeList = ref([])
let exercise_now = ref({})
let contentHeight = ref(0)
let leftHeight = ref("")
let radioAnswer = ref("")
let localIndex = ref(0)
let doneNum = ref(0)
let answerList = ref([])
const visible = ref(false)
const confirm = ref(false)
const calculatorVisible = ref(false)

function showDialog(){
  visible.value = true
}
function handleOk(){
  if(confirm.value){
    visible.value = false
    moveToNext()
  }else{
    Message.info('请勾选弹窗中【我确认结束考试，考试后不可再次作答】再点击确认')
  }
}
function handleCancel(){
  visible.value = false
}

window.onresize = function (){
  contentHeight.value = window.innerHeight - 48 - 51
  leftHeight.value = contentHeight.value - 378 + 'px'
}

function lastExercise(){
  const index = exercise_now.value.index
  if (index <= 0){
    return
  }
  changeExercise(index - 1)
}

function nextExercise(){
  const index = exercise_now.value.index
  if (index >= exerciseList_packed.value.length - 1){
    return
  }
  changeExercise(index + 1)
}
function changeExercise(index){
  exercise_now.value = exerciseList_packed.value[index]
  radioAnswer.value = exerciseList_packed.value[index].answer
  answerList.value = exerciseList_packed.value[index].answerList
  localIndex.value = index
  console.log('time',document.getElementById('countdown').innerText)
}

function mark(){
  const index = exercise_now.value.index
  exerciseList_packed.value[index].isMarked = !exerciseList_packed.value[index].isMarked
}

function onOptionChange(option) {
  const index = exercise_now.value.index
  exerciseList_packed.value[index].answer = option
  exerciseList_packed.value[index].isDone = true
  updateDone()
}

function onInput(){
  const index = exercise_now.value.index
  exerciseList_packed.value[index].answerList = answerList.value
  exerciseList_packed.value[index].isDone = answerList.value.length >= exerciseList_packed.value[index].question.length;
  for (const ansIndex in answerList.value) {
    console.log('answer',answerList.value[ansIndex])
    if (answerList.value[ansIndex].length === 0){
      exerciseList_packed.value[index].isDone = false
    }
  }
  updateDone()
}

function updateDone(){
  doneNum.value = 0
  for (const index in exerciseList_packed.value) {
    if (exerciseList_packed.value[index].isDone){
      doneNum.value += 1
    }
  }
}

function numberToChinese(number) {
  const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const chineseUnits = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿'];

  // 将数字转换为字符串
  const numberStr = number.toString();

  // 初始化结果字符串
  let result = '';

  // 遍历数字字符串
  for (let i = 0; i < numberStr.length; i++) {
    const digit = parseInt(numberStr[i]);
    const unitIndex = numberStr.length - i - 1;

    // 处理零
    if (digit === 0) {
      // 如果当前数字是零，只有在不是连续的零时才添加中文零
      if (i === 0 || numberStr[i - 1] !== '0') {
        result += chineseNumbers[digit];
      }
    } else {
      // 处理非零数字
      result += chineseNumbers[digit] + chineseUnits[unitIndex];
    }
  }

  return result;
}

const router = useRouter()
function moveToNext(){
  router.push({
    name: "MockEnd",
  })
}
function timeOut(){
  moveToNext()
}

function getMockName(){
  switch (subjectName){
    case '程序员': return 'cxy';
    case '多媒体应用设计师': return 'dmtyysjs';
    case '电子商务设计师': return 'dzswsjs';
    case '嵌入式系统设计师': return 'qrsxtsjs';
    case '软件评测师': return 'rjpcs';
    case '软件设计师': return 'rjsjs';
    case '数据库系统工程师': return 'sjkxtgcs';
    case '网络工程师': return 'wlgcs';
    case '网络规划设计师': return 'wlghsjs';
    case '网络管理员': return 'wlgly';
    case '系统分析师': return 'xtfxs';
    case '系统规划与管理师': return 'xtghygls';
    case '系统集成项目管理工程师': return 'xtjcxmglgcs';
    case '系统架构设计师': return 'xtjgsjs';
    case '信息安全工程师': return 'xxaqgcs';
    case '信息处理技术员': return 'xxcljsy';
    case '信息系统管理工程师': return 'xxxtglgcs';
    case '信息系统监理师': return 'xxxtjls';
    case '信息系统项目管理师': return 'xxxtxmgls';
  }
}

function showCalculator(){
  calculatorVisible.value = true
}
function calculatorClose(){
  calculatorVisible.value = false
}

function downloadFile(){
  // 文件的远程地址
  const fileUrl = 'https://static.trybest-learning.com/rk/pc/PTCalc.zip';
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = 'PTCalc.zip';
  link.click();
}

onMounted(() => {
  const mockName = getMockName()
  axios.get('./'+mockName+'.json').then((res) => {
    contentHeight.value = window.innerHeight - 48 - 51
    leftHeight.value = contentHeight.value - 378 + 'px'

    exerciseList = res.data
    let tempType = exerciseList[0].type
    let tempTypeList = []
    for (const exerciseListKey in exerciseList) {
      const i = exerciseList[exerciseListKey]
      const temp = {
        subject: i.subject,
        type: i.type,
        typeExplain: i.typeExplain,
        questionContent: i.questionContent,
        question: i.question,
        questionA: i.questionA,
        questionB: i.questionB,
        questionC: i.questionC,
        questionD: i.questionD,
        index: parseInt(exerciseListKey),
        answer: "",
        answerList: [],
        isDone: false,
        isMarked: false
      }
      console.log('question',temp.questionContent)
      if (i.question.length !== 0) {
        temp.question = i.question.split("xntk")
      }
      exerciseList_packed.value.push(temp)

      if (i.type === tempType) {
        tempTypeList.push(temp)
      } else {
        exerciseTypeList.value.push(tempTypeList)
        tempTypeList = []
        tempType = i.type
        tempTypeList.push(temp)
      }
    }
    exerciseTypeList.value.push(tempTypeList)

    exercise_now.value = exerciseList_packed.value[0]
  })
})
</script>

<style>
#mockExercise {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  background-color: #F7F8FA;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  user-select: none;
}

.header-ex {
  width: 100vw;
  height: 48px;
  background: #1F63FB;
  display: flex;
  align-items: center;
}

.content-ex {
  width: 100vw;
  display: flex;
  flex-grow: 1;
  margin-bottom: 1px;
  min-height: 0;
  overflow: visible
}

.footer-ex {
  width: 100vw;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
}

.collapse-container {
  width: 100%;
  height: auto;
}

.collapse-content {
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #D8D8D8;
  color: #1D2129;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.collapse-content-done {
  width: 24px;
  height: 24px;
  border: 1px solid #D8D8D8;
  color: #FFFFFF;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #00B42A;
  border-radius: 4px 4px 4px 4px;
  opacity: 0.7;
}

.collapse-content-local {
  width: 24px;
  height: 24px;
  background: #165DFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #D8D8D8;
  color: #FFFFFF;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.left-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 48px;
}

.triangle {
  justify-self: flex-end;
  align-items: flex-end;
  width: 0;
  height: 0;
  border-bottom: 11px solid #F53F3F;
  border-left: 11px solid transparent;
  position: absolute;
  bottom: 0;
  right: 0;
}

.single-multiple {
  flex-grow: 1;
  background: #FFFFFF;
  border-radius: 0px 0px 3px 3px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  padding: 16px;
  overflow: hidden;
}
.alfx{
  flex-grow: 1;
  background: #FFFFFF;
  border-radius: 0px 0px 3px 3px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  overflow: hidden;
}
.left-scrollbar{
  overflow: auto;
  width: 196px;
  background-color: #FFFFFF;
}
.question-container{
  min-height: 0;
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  flex-grow: 1;
}
.buttonBlock-dialog{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>