<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto;">
    <div id="userInfo">
      <div
          style="display: flex;align-items: center;justify-content: center;width: 100%;height: 82px;background-color: #2E72B8;">
        <div style="flex-grow: 1;"/>
        <a-image :src="require('../assets/img_logo_ks_normal.png')"/>
        <h1 class="pageTitle-login">全国计算机技术与软件专业技术资格(水平)考试机考模拟系统</h1>
        <div style="flex-grow: 1;"/>
        <div class="top-right-corner">机号1</div>
      </div>
      <div class="content-ui">
        <div class="inputContainer-ui">
          <span class="inputTitle-ui">考生信息</span>
          <a-divider :margin="0"></a-divider>
          <div style="display: flex;width: 688px;margin-top: 24px;margin-bottom: 24px;">
            <a-image :width="160" :height="204" :src="require('../assets/img_avatar_big_normal.png')" style="margin-left: 20px"></a-image>
            <div style="margin-left: 33px;height: 204px">
              <div style="display: flex;">
                <div class="tableHead" style="border-bottom-width: 0;border-right-width: 0">姓名</div>
                <div class="tableContent" style="border-bottom-width: 0">演示考生</div>
              </div>
              <div style="display: flex;">
                <div class="tableHead" style="border-bottom-width: 0;border-right-width: 0">性别</div>
                <div class="tableContent" style="border-bottom-width: 0">男</div>
              </div>
              <div style="display: flex;">
                <div class="tableHead" style="border-bottom-width: 0;border-right-width: 0">准备考证号</div>
                <div class="tableContent" style="border-bottom-width: 0">1234</div>
              </div>
              <div style="display: flex;">
                <div class="tableHead" style="border-bottom-width: 0;border-right-width: 0">证件号码</div>
                <div class="tableContent" style="border-bottom-width: 0">1234567890</div>
              </div>
              <div style="display: flex;">
                <div class="tableHead" style="border-right-width: 0">考试科目</div>
                <div class="tableContent">{{ subjectName }}</div>
              </div>
            </div>
          </div>
          <a-divider :margin="0"></a-divider>

<!--          <a-tooltip content="若您的个人信息存在错误，请联系现场工作人员进行处理"-->
<!--                     content-class="tooltipContent"-->
<!--                     position="bottom" background-color="#FFFFFF">-->

<!--          </a-tooltip>-->
          <div class="buttonBlock-ui">
            <a-button style="width: 108px;height: 36px;background-color: #165DFF;color: #FFFFFF;" @click="moveToNext">
              确认
            </a-button>
            <a-button style="width: 108px;height: 36px;margin-left: 16px" @click="goBack">取消</a-button>
          </div>
        </div>
      </div>
      <div class="footer-select-subject">
        <span style="margin-right: 344px;font-size: 14px;font-weight: 400;color: #4E5969;">郑重声明：练习试卷旨在让考生熟悉机考环境，试题类型和作答方式，正式考试试卷的试题类型、试题数量、分值分布和机考界面等，可能会做适当调整。</span>
<!--        <div class="stamp-1">-->
<!--          <a-image width="92" height="92" :src="require('../assets/downlaod.jpg')"></a-image>-->
<!--          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">下载【软考通】APP</span>-->
<!--        </div>-->
<!--        <div class="stamp-2">-->
<!--          <a-image width="92" height="92" :src="require('../assets/official.jpg')"></a-image>-->
<!--          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">关注公众号领资料大全</span>-->
<!--        </div>-->
      </div>

      <div style="position: absolute;bottom: 0;right: 0;display: flex;margin-bottom: 40px;">
        <div class="stamp-1">
          <a-image width="92" height="92" :src="require('../assets/downlaod.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">下载【软考通】APP</span>
        </div>
        <div class="stamp-2">
          <a-image width="92" height="92" :src="require('../assets/official.jpg')"></a-image>
          <span style="font-size: 12px;font-weight: 400;color: #4E5969;margin-top: 12px">关注公众号领资料大全</span>
        </div>
      </div>

    </div>
  </a-scrollbar>
</template>

<style>
#userInfo {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  min-width: 1400px;
  margin: 0 auto;
  display: flex;
//background: linear-gradient(#1F63FB, #428DFF); justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background: url("../assets/back.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.pageTitle-ui {
  margin-top: 8px;
  width: 930px;
  font-size: 45px;
  font-weight: 600;
  font-family: PingFang SC-Semibold, PingFang SC;
  color: #FFFFFF;
  text-align: center;
}

.content-ui {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.inputContainer-ui {
  margin-top: 80px;
  width: 688px;
  height: auto;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputTitle-ui {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1D2129;
  text-align: center;
}

.buttonBlock-ui {
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableHead {
  display: flex;
  width: 100px;
  height: 40px;
  background-color: #F7F8FA;
  border: 1px solid #E5E6EB;
  color: #86909C;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;
}

.tableContent {
  display: flex;
  width: 314px;
  height: 40px;
  background-color: #FFFFFF;
  border: 1px solid #E5E6EB;
  color: #1D2129;
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  justify-content: flex-start;
  align-items: center;
}

.tooltipContent {
  color: #1D2129;
  font-size: 14px;
  font-weight: 400;
}
</style>

<script setup>
import {useRouter} from "vue-router";

const subjectName = sessionStorage.getItem("subjectName")

const router = useRouter()

function goBack() {
  router.go(-1)
}

function moveToNext() {
  router.push({
    name: 'MockExercise',
  })
}
</script>

<script>
export default {
  data() {
    return {};
  }
}
</script>