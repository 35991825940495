<template>
  <a-scrollbar style="width: 100vw;height: 100vh;overflow: auto;">
    <div id="specialTips">
      <div class="header">
        <div style="font-size: 18px;font-weight: 400;color: #FFFFFF;margin-left: 20px;">全国计算机技术与软件专业技术资格(水平)考试机考模拟系统</div>
        <div style="flex-grow: 1;"></div>
        <div style="font-size: 16px;font-weight: 500;color: #FFFFFF;">已完成0/41题</div>
        <div class="countdown-container">剩余：00：58：29</div>
        <div class="lock-button"><icon-export style="margin-right: 5px"/>暂离锁屏</div>
        <div style="font-size: 16px;font-weight: 500;color: #FFFFFF;margin-left: 16px;">座位号：</div>
        <div style="
        width: 24px;height: 24px;font-size: 14px;
        font-weight: 600;color: #165DFF;
        background-color: #FFFFFF;
        margin-left: 4px;margin-right: 16px;
        display: flex;justify-content: center;align-items: center;">1</div>
      </div>
      <div class="content">
        <div class="tip-container">
          <div style="display: flex;justify-content: center;align-items: center;width: 100%;margin-top: 24px;margin-bottom: 24px;">
            <icon-info-circle-fill style="color: #165DFF;margin-right: 4px"/>
            <span class="inputTitle-tips">特别提示</span>
          </div>
          <a-space direction="vertical" :size="12" style="margin-left: 32px;margin-right: 32px;">
            <div style="width: 624px;height: 22px;font-size: 14px;font-weight: 500;color: #1D2129;">在开始答题前，请考生关注下列事项：</div>
            <div class="tip-text">一、本试题卷计算分析题中有一道小题可以选用中文或英文解答，请仔细阅读答题要求，如使用英文解答，须全部使用英文，答题正确的，增加5分。试卷最高得分为105分，本科目合格分数线为60分。</div>
            <div class="tip-text">二、设计计算的，如有小数，保留两位小数，两位小数后四舍五入。</div>
            <div class="tip-text">三、考生在草稿纸上答题一律无效，考试结束后，不得带草稿纸带出考场。</div>
          </a-space>
          <a-button type="primary" style="width: 108px;height: 32px;
          background: #165DFF;border-radius: 2px 2px 2px 2px;margin-bottom: 32px;margin-top: 24px;"
          @click="moveToNext">
            继续
          </a-button>
        </div>
      </div>
    </div>
  </a-scrollbar>
</template>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter()
function moveToNext(){
  router.push({
    name: "MockMenu",
  })
}
</script>

<style>
#specialTips {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  background-color: #F7F8FA;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}
.header{
  width: 1440px;
  height: 48px;
  background: #1F63FB;
  display: flex;
  align-items: center;
}
.content{
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.countdown-container{
  margin-left: 16px;
  width: 153px;
  height: 32px;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lock-button{
  margin-left: 16px;
  width: 110px;
  height: 32px;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tip-container{
  width: 688px;
  height: 314px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tip-text{
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  line-height: 22px;
}
</style>